<template>
    <div class="page">
        <div class="content">
            <el-form class="tb-form" inline :model="searchInfo" label-position="left">
                <el-form-item label="用户昵称:">
                    <el-input placeholder="请输入" v-model="searchInfo.nick_name"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="searchInfo.status">
                        <el-option label="全部" :value="null"/>
                        <el-option label="无效" :value="0"/>
                        <el-option label="有效" :value="1"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="评论时间:">
                    <el-date-picker
                          v-model="searchInfo.date"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
                        </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch">查询</el-button>
                    <el-button @click="resetParams">重置</el-button>
                </el-form-item>
            </el-form>
            <div class="tablebox">
                <el-table :data="list" class="tb-table">
                    <el-table-column prop="id" label="ID" width="60"></el-table-column>
                    <el-table-column prop="nick_name" label="用户昵称" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="content" label="评论内容" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="created_at" label="评论时间"></el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            {{scope.row.status | getStatusName}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" @click="toggleStatus(scope.row)">{{scope.row.status ? "下架" : "上架"}}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        background
                        class="tb-pagination"
                        layout="prev, pager, next"
                        :current-page.sync="curPage"
                        :page-size="20"
                        :total="totalCount"
                        @current-change="onSearch"
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchInfo: {
                    nick_name:'',
                    status: null,
                    date:[],
                    start_time:'',
                    end_time:''
                },
                curPage: 1,
                totalCount:20,
                list:[]
            }
        },
        mounted() {
          this.onSearch()  
        },
        methods:{
            resetParams() {
                this.searchInfo.nick_name = ''
                this.searchInfo.status = null
                this.searchInfo.date = []
                this.curPage = 1
                this.onSearch()
            },
            async onSearch() {
                let params = {page_no: this.curPage - 1, page_size:20, nick_name:this.searchInfo.nick_name};
                if (this.searchInfo.date) {
                    params.start_time = this.searchInfo.date[0];
                    params.end_time = this.searchInfo.date[1]
                }
                if (this.searchInfo.status !== null) {
                    params.status = this.searchInfo.status
                }
                const url = 'admin/feed/comment/list'
                let data = await this.$https.get(url, {params})
                if (data) {
                    this.list = data.list
                    this.totalCount = data.total_count
                }
            },
            toggleStatus(row){
                this.$confirm('您确定要' + (row.status === 1 ? '下架' : '上架') + '这条评论吗？', '', {
                    onfirmButtonText: '确定',
                              cancelButtonText: '取消',
                              type: 'warning'
                }).then(() => {
                    const url = 'admin/feed/comment/modify/status'
                    const params = {id: row.id, status: row.status === 1 ? 0 : 1}
                    this.$https.post(url, params).then(res => {
                        if (res) {
                            this.onSearch()
                            if (row.status === 1) {
                                this.$message.success('下架成功')
                            } else {
                                this.$message.success('上架成功')
                            }
                        }
                    })
                }).catch(() => {})
            }
        },
        filters:{
            getStatusName(status) {
                return status === 0 ? '无效' : '有效'
            }
        }
    }
</script>

<style>
</style>
